import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Button,
    Divider,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Snackbar,
    Alert,
} from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import pmo from "../../assets/pmo.gif";
import "./mybasket.css";
// import "../DiscountCard/DiscountCard.css";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../component/Spinner/Spinner";
import OfferModal from "../ModalComp/OfferModal";
import axiosApiFunction from "../../utils/axiosapifunc";
import OfferContext from "../../context/OfferContext";
import ErrorContext from "../../context/ErrorContext";
import { addCart, applyOffer, applyOrderLevelCharge, isDeliveryOrder, recalcOrderLevelCharge, removeItem, removeOffer } from "../../redux/slice/CartSlice";
import moment from "moment";
import ToggleSwitch from "../Toggle/ToggleSwitch";
import DrawerComp from '../Drawer/DrawerComp'
import { useNavigate } from "react-router-dom";
import { pushStoreData } from "../../redux/slice/StoreSlice";
import { img } from "framer-motion/client";

const MyBasket = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [offerData, setOfferData] = useState(false);
    // const [isOfferApplied, setIsOfferApplied] = useState(false);
    // const [appliedOfferName, setAppliedOfferName] = useState("");
    // const [appliedOfferDescription, setAppliedOfferDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [openAddress, setOpenAddress] = useState(false);
    const [storeDetail, setStoreDetail] = useState([]);
    const [isDelivery, setIsDelivery] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [itemLevelCharge, setItemLevelCharge] = useState({});
    const [orderLevelCharge, setOrderLevelCharge] = useState({});
    const [totalWoutRoundoff, setTotalWoutRoundoff] = useState(0);
    
    const userToken = JSON.parse(localStorage.getItem('tokenData')).token;
    const locationData = JSON.parse(localStorage.getItem('locationData'));

    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);
    const offerCtx = useContext(OfferContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStore = useSelector((state) => state.store?.storeData);
    const getCart = useSelector((state) => state.cart?.cartData);
    const getTotalTax = useSelector((state) => state.cart?.totalTax);
    const getTotalAmount = useSelector((state) => state.cart?.totalAmount);
    const getTotalDiscount = useSelector((state) => state.cart?.totalDiscount);
    const getTotalAmountWithTax = useSelector((state) => state.cart?.totalAmountWithTax);
    const totalItemLevelCharge = useSelector((state) => state.cart?.totalItemLevelCharge);
    const totalOrderLevelCharge = useSelector((state) => state.cart?.orderLevelCharge);
    let isDeliveryOrderFlag = useSelector((state) => state.cart?.isDeliveryOrder)

    const getTokenData = JSON.parse(localStorage.getItem("tokenData"));
    let getUserData = JSON.parse(localStorage.getItem("userData"));
    const companyId = JSON.parse(localStorage.getItem('companyId'));
    const getTableId = JSON.parse(localStorage.getItem('tblid')) || 0;
    const [showImage, setShowImage] = useState(true); 
    getUserData.isDeliveryType = isDelivery ? 1 : 0;
    localStorage.setItem('userData', JSON.stringify(getUserData));

    
    let totalToPay = parseFloat(
        (
            Number(getTotalAmountWithTax) +
            (getTableId == 0 ? Number(totalItemLevelCharge) : 0) +
            (isDelivery && getTableId == 0 ? Number(totalOrderLevelCharge) : 0)
        ).toFixed(2)
    );

    let roundoff = Math.round(totalToPay) - totalToPay;

    totalToPay = (totalWoutRoundoff == 0 && getStore?.pgid != 3) ? Math.round(totalToPay): totalToPay;



    let totalGST = 0;

    for (const key in getTotalTax) {
        if (key === "SGST" || key === "CGST") {
            totalGST = totalGST + getTotalTax[key];
        }
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleOpenModal = () => {
        setOpenDialog(true);
    };

    const handleOfferClick = async (offerdata) => {
        setOpenDialog(false);
        if (getCart?.length === 0) {
            setOpen(true);
            setMessage("Please Add Product To Cart");
            setSeverity("info");
            return;
        }

        let itemPayload = [];

        for (let i = 0; i < getCart.length; i++) {
            let modifiersArray = [];
            if (getCart[i].optionsToAdd.length > 1) {
                modifiersArray = getCart[i].optionsToAdd.slice(1).map((option) => {
                    return {
                        modifiername: option.title,
                        modifiercategoryid: option.optGroupRefIds[0][0],
                        modifierid: option.refId,
                        price: option.price,
                        quantity: 1, // You can adjust this if quantity is different
                    };
                });
            }

            for (let j = 0; j < getCart[i].quantity; j++) {
                itemPayload.push({
                    modifiers: modifiersArray,
                    productid: getCart[i].refId.toString(),
                    productcategoryid: getCart[i].categoryRefIds[0],
                    productportionid:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].refId
                            : "",
                    productname: getCart[i].title,
                    productQty: 1,
                    taxprofileid: 0,
                    producttaxrate: getCart[i].itemTotalTax,
                    portionname:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].title
                            : getCart[i].portionName,
                    price:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].price
                            : parseFloat(getCart[i].price.toFixed(2)),
                    //   productPortionId: variation.refId,
                });
            }
        }

        let applyOfferPayload = {
            offerId: offerdata.offerid,
            amount: getTotalAmount,
            customerId: getTokenData.data?.customerdetails
                ? getTokenData.data?.customerdetails.customerid
                : 0,
            items: itemPayload,
        };

        try {
            clearApiError();
            setIsLoading(true);

            const response = await axiosApiFunction(
                "post",
                "/api/v1/applyoffer",
                {
                    companyid: companyId,
                    locationid: locationData.locationid,
                },
                userToken,
                applyOfferPayload
            );

            if (response.data.success === 1) {
                dispatch(removeOffer());
                dispatch(applyOffer(response.data.items));
                setOpen(true);
                setMessage("Offer applied successfully!");
                setSeverity("success");
                offerCtx.handleOfferNameChange(offerdata.offername);
                offerCtx.handleOfferDescriptionChange(offerdata.offerdescription);
                offerCtx.handleOffer(true);

                const offerData = {
                    offername: offerdata.offername,
                    offerdescription: offerdata.offerdescription,
                    isofferapplied: true
                }

                localStorage.setItem('offerData', JSON.stringify(offerData));
            } else {
                dispatch(removeOffer());
                offerCtx.resetOffer();

                const offerData = {
                    offername: "",
                    offerdescription: "",
                    isofferapplied: false
                }

                localStorage.setItem('offerData', JSON.stringify(offerData));

                setOpen(true);
                setMessage(response.data.msg);
                setSeverity("error");
            }
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        clearApiError();
        const companyId = JSON.parse(localStorage.getItem('companyId'));
        const offerData = JSON.parse(localStorage.getItem('offerData'));

        getTableId == 0 ? setIsDelivery(true): setIsDelivery(false);

        if (offerData) {
            offerCtx.handleOfferNameChange(offerData.offername);
            offerCtx.handleOfferDescriptionChange(offerData.offerdescription);
            offerCtx.handleOffer(offerData.isofferapplied);
        }

        async function getStoreDetails() {
            try {
                let storeDetails = await axiosApiFunction(
                    "get",
                    "/api/v1/getstoredetails",
                    {
                        companyid: companyId,
                        locationid: locationData.locationid,
                    },
                    userToken
                );
                dispatch(pushStoreData(storeDetails.data[0]));
                setStoreDetail(storeDetails.data);
                setTotalWoutRoundoff(storeDetails.data[0].totalwoutroundoff);
            } catch (error) {
                setApiError(error.message);
            }

        }

        async function fetchOfferData() {
            try {
                setIsLoading(true);

                const response = await axiosApiFunction(
                    "get",
                    "/api/v1/getoffers",
                    { companyid: companyId, locationid: locationData.locationid },
                    userToken
                );
                setOfferData(response.data);
            } catch (error) {
                setApiError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        fetchOfferData();
        getStoreDetails();
    }, []);
   
    useEffect(() => {
        // Set a timer to hide the image after 4 seconds
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 4000);
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsDelivery(isDeliveryOrderFlag);
        getCart.forEach((item) => {
            if(item?.isOrderLevelChargedProduct == true){
                dispatch(applyOrderLevelCharge(Number(getStore?.charges?.find(charge => charge.applicableon == 2).chargevalue)))
            }
        })
    }, [getStore, isDeliveryOrderFlag])

    useEffect(() => {
        setItemLevelCharge({chargeName: getStore?.charges?.find(charge => charge.applicableon == 1)?.chargename, chargeAmt: totalItemLevelCharge})
        setOrderLevelCharge({chargeName: getStore?.charges?.find(charge => charge.applicableon == 2)?.chargename, chargeAmt: totalOrderLevelCharge})
    }, [totalItemLevelCharge, totalOrderLevelCharge])

    const isStoreOpen = (timings) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
        const currentTime = now.toTimeString().slice(0, 8);

        const getPriviousDay = (dayIndex) => {
            return dayIndex === 0 ? timings.length - 1 : dayIndex - 1;
        };

        const currentDayIndex = timings.findIndex((timing) => timing.day === currentDay);

        if (currentDayIndex === -1) {
            return false;
        }

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);
                    const endTime = slot.end_time.slice(0, 8);

                    if (startTime < endTime) {
                        if (currentTime >= startTime && currentTime < endTime) {
                            return true;
                        }
                    } else {
                        const previousDayIndex = getPriviousDay(currentDayIndex);
                        const previousDayTimings = timings[previousDayIndex];

                        for (const slot of previousDayTimings.slots) {
                            const previousDayEndTime = slot.end_time.slice(0, 8);

                            if (currentTime >= startTime) {
                                return true;
                            }

                            if (currentTime < previousDayEndTime) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return false;
    };

    const handleCheckoutClick = () => {
        let enableHomeDel = storeDetail[0]?.enable_homedelivery;
        let enablePickupDel = storeDetail[0]?.enable_pickup;

        let minOrderValueHomeDel = storeDetail[0]?.min_order_value_for_homedelivery;
        let minOrderValuePickup = storeDetail[0]?.min_order_value_for_pickup;

        let paylaterEnable = storeDetail[0]?.enablepaylater;
        let onlinePaymentAvailable = false;
        let pgid =  storeDetail[0]?.pgid;

        if(pgid > 0) onlinePaymentAvailable = true;

        if (getCart?.length === 0) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Your cart is currently empty");
            setSeverity("error");
            return;
        }

        // || !(Object.keys(getStore).length > 0 && isStoreOpen(getStore.timings)
        let storeBoolean = (storeDetail[0]?.active == "true" || storeDetail[0]?.active == true) ? 1 : 0;
        if (!storeBoolean || !(Object.keys(getStore).length > 0 && isStoreOpen(getStore.timings))) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Currently store is closed");
            setSeverity("error");
            return;
        }

        if(!(onlinePaymentAvailable || (paylaterEnable == 1))) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Currently no payment option available");
            setSeverity("error");
            return;
        }

        if ((enableHomeDel == false || enableHomeDel == "false") && isDelivery && getTableId == 0) {
            setOpen(true);
            setMessage("Delivery Service is Currently Unavailable at the store");
            setSeverity("warning");
            return;
        }

        if ((enablePickupDel == false || enablePickupDel == "false") && !isDelivery && getTableId == 0) {
            setOpen(true);
            setMessage("Pick-up Service is Currently Unavailable at the store");
            setSeverity("warning");
            return;
        }

        if (minOrderValueHomeDel && minOrderValueHomeDel > 0 && isDelivery && minOrderValueHomeDel > getTotalAmountWithTax && getTableId == 0) {
            setOpen(true);
            setMessage(`The minimum order value for home delivery is ${Number(minOrderValueHomeDel).toFixed(2)} Please add more items to continue.`);
            setSeverity("warning");
            return;
        }
        

        if (minOrderValuePickup && minOrderValuePickup > 0 && !isDelivery && minOrderValuePickup > getTotalAmountWithTax && getTableId == 0) {
            setOpen(true);
            setMessage(`The minimum order value for pickup is ${Number(minOrderValuePickup).toFixed(2)} Please add more items to continue.`);
            setSeverity("warning");
            return;
        }

        isDelivery ? setIsDrawerOpen(true) : handleSubmit(isDelivery);
    }


    const handleSubmit = async () => {

        clearApiError();

        let customerData = await getTokenData.data;

        // let itemsArrayToSave = await getCart.map((item) => {
        //     let itemOptions = item.optionsToAdd.map((option) => ({
        //         // id: option.id,
        //         merchant_id: option.refId.toString(),
        //         price: option.price,
        //         title: option.title,
        //         translations: null,
        //         unit_weight: option.weight,
        //     }));

        //     let modifiers =
        //         item.optionsToAdd.length > 1
        //             ? item.optionsToAdd.slice(1).map((modifier) => ({
        //                   modifercategory: modifier.optGroupRefIds[0][0],
        //                   modifierprice: modifier.price,
        //                   modifierqty: 1,
        //                   modifierid: modifier.refId,
        //                   productmodifierid: "",
        //               }))
        //             : [];

        //     let productDiscount = parseFloat(
        //         (Number(item.discount) / 100) *
        //             (item.optionsToAdd.length > 0 ? item.optionsToAdd[0].price : item.price) *
        //             item.quantity
        //     ).toFixed(3);

        //     let calculateTotalTaxablePrice =
        //         item.optionsToAdd.length > 0
        //             ? parseFloat(
        //                   (
        //                       (item.totalPrice -
        //                           item.optionsToAdd
        //                               .slice(1)
        //                               .reduce((acc, mod) => acc + mod.price, 0)) *
        //                           item.quantity *
        //                           (1 - Number(item.discount) / 100) +
        //                       item.optionsToAdd.slice(1).reduce((acc, mod) => acc + mod.price, 0) *
        //                           item.quantity
        //                   ).toFixed(2)
        //               )
        //             : parseFloat(
        //                   (
        //                       item.totalPrice *
        //                       item.quantity *
        //                       (1 - Number(item.discount) / 100)
        //                   ).toFixed(2)
        //               );

        //     let itemTotal = item.totalPrice * item.quantity;

        //     return {
        //         product_id: item.refId, // *** need to be changed */
        //         productcategoryid: item.categoryRefIds[0],
        //         modifiers: modifiers,
        //         taxprofileid: "",
        //         productportionid: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].refId : "",
        //         productname: item.title,
        //         price: parseFloat(item.price),
        //         quantity: item.quantity.toString(),
        //         taxableprice: calculateTotalTaxablePrice,
        //         totaltaxprice: calculateTotalTaxablePrice + item.itemTotalTax,
        //         total: itemTotal,
        //         charges: "",
        //         discountpercentage: item.discount,
        //         discount: productDiscount,
        //         taxes: item.itemLevelTax.map((tax) => ({
        //             rate: tax.structure.value.toString(),
        //             value: parseFloat((tax.structure.value * item.price) / 100),
        //             taxid: tax.title == "SGST" ? "1" : tax.title == "CGST" ? "2" : "",
        //         })),
        //         portionid: null,
        //         portionname: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].title : null,
        //         offerid: item.offerId.toString(),
        //         promocode: "",
        //         offertype: "",
        //         discountedqty: 0,
        //         discounttype: 1,
        //         discounonproduct: productDiscount,
        //         totalwithoutdiscountortax: itemTotal,
        //         pricewithouttaxandmodifier: itemTotal,
        //         discountapplied: item.discount > 0 ? 1 : 0,
        //     };
        // });

        let itemsArray = await getCart.map((item) => {
            let itemOptions = item.optionsToAdd
            .filter(option => Object.keys(option).length > 0) // Exclude empty objects
            .map(option => ({
                merchant_id: option.refId.toString(),
                price: option.price,
                title: option.title,
                translations: null,
                unit_weight: option.weight,
            }));

            return {
                charges: [],
                discount: parseFloat(item.discount),
                food_type: item.foodType.toString(),
                image_landscape_url: null,
                image_url: item.imgUrl,
                merchant_id: item.refId.toString(),
                options_to_add: itemOptions,
                options_to_remove: [], // ***
                price: parseFloat(item.price),
                quantity: item.quantity,
                taxes: item.itemLevelTax.map((tax) => ({
                    rate: tax.structure.value,
                    title: tax.title,
                    value: parseFloat((item.itemTotalTax / 2).toFixed(3)),
                })),
                title: item.title,
                offerid: item.offerId,
                total: (item.totalPrice * item.quantity).toString(),
                total_with_tax: parseFloat(
                    parseFloat(item.totalPrice) + parseFloat(item.itemTotalTax)
                ),
                translations: null,
                unit_weight: item.weight,
            };
        });

        let productData = {
            companyid: companyId,
            locationid: locationData.locationid,
            items: itemsArray,
        };

        // return;

        try {
            setIsLoading(true);

            const response = await axiosApiFunction(
                "post",
                "/api/v1/validateorder",
                {},
                userToken,
                productData
            );

            let resultResp = response.data;
            // return;
            if (
                resultResp.success === 1 &&
                resultResp.invalid_portions === 0 &&
                resultResp.invalid_products === 0
            ) {
                navigate('/payment');
            } else {
                if (resultResp.invalid_products > 0 || resultResp.invalid_portions > 0) {
                    const invalidProducts = resultResp.data?.invalid_products || {};
                    const invalidPortions = resultResp.data?.invalid_portions || {};

                    // const filteredCart = getCart.filter((item) => {
                    //     const isValidProduct = !(item.refId in invalidProducts);

                    //     const areValidOptions = item.optionsToAdd.every((option) => {
                    //         return !(option.refId in invalidPortions);
                    //     });

                    //     return isValidProduct && areValidOptions;
                    // });

                    const cartWithInvalidFlags = getCart.map((item) => {
                        const isInvalidProduct = item.refId in invalidProducts;
                        const hasInvalidOptions = item.optionsToAdd.some((option) => {
                            return option.refId in invalidPortions;
                        });

                        return {
                            ...item,
                            isInvalidProduct,  // Mark product as invalid
                            hasInvalidOptions, // Mark if product has invalid options
                        };
                    });

                    dispatch(addCart(cartWithInvalidFlags));

                    setOpen(true);
                    setMessage("Some product(s) from the cart are invalid. Please remove them to proceed to checkout");
                    setSeverity("error");
                }
            }
        } catch (error) {
            setOpen(true);
            setMessage(error.message);
            setSeverity("error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveOffer = () => {
        offerCtx.resetOffer();

        const offerData = {
            offername: "",
            offerdescription: "",
            isofferapplied: false
        }
        localStorage.setItem('offerData', JSON.stringify(offerData));
        dispatch(removeOffer());
    }

    const handleSwitch = (flag) => {
        dispatch(isDeliveryOrder(flag));
        setIsDelivery(flag);
    }

    return (
        <div>
            <Spinner enabled={isLoading} />
            <div className="card">
                {showImage ? (
                    // Show only the image for 4 seconds
                    <img className="setpmoimg" src={pmo} alt="Offer" />
                ) : (
                    <>
                        <div className="code">
                            <div className="icon">%</div>
                            <div className="code-text">
                                {offerCtx.isOfferApplied
                                    ? offerCtx.appliedOfferName
                                    : offerData.length > 0
                                    ? offerData[0].offername
                                    : "No Offer Available"}
                            </div>
                        </div>
                        <div className="description">
                            {offerCtx.isOfferApplied
                                ? offerCtx.appliedOfferDescription
                                : offerData.length > 0
                                ? offerData[0].offerdescription
                                : "No offers available at the moment."}
                        </div>
                        <div className="actions">
                            {offerCtx.isOfferApplied ? (
                                <a className="apply-link" onClick={() => handleRemoveOffer()}>
                                    Remove Offer
                                </a>
                            ) : (
                                <a
                                    className="apply-link"
                                    onClick={() => handleOfferClick(offerData[0])}
                                >
                                    Apply
                                </a>
                            )}
                            <a className="more-offers" onClick={handleOpenModal}>
                                View more offers
                            </a>
                        </div>
                    </>
                )}

                {/* <img className="setpmoimg" src={pmo} /> */}
            </div>
            <Box className="basket">
                <Box className="basket-header">
                    <ShoppingBasketIcon className="basket-icon" />
                    <Typography variant="h6" className="basket-title">
                        My Basket
                    </Typography>
                </Box>
                <Divider />
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                        style={{ marginTop: "50px", textAlign: "start" }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
                <Box className="basket-summary">
                    <Box className="basket-summary-item">
                        <Typography>Sub Total:</Typography>
                        <Typography>
                            {getStore?.currencysymbol}{" "}
                            {parseFloat((getTotalAmount + getTotalDiscount).toFixed(2))}
                        </Typography>
                    </Box>
                    <Box className="basket-summary-item">
                        <Typography>Discounts:</Typography>
                        <Typography>
                            {getStore?.currencysymbol} {getTotalDiscount}
                        </Typography>
                    </Box>
                    <Box className="basket-summary-item">
                        <Typography>GST:</Typography>
                        <Typography>
                            {getStore?.currencysymbol} {totalGST}
                        </Typography>
                    </Box>
                    {totalWoutRoundoff == 0 && getStore?.pgid != 3 && (
                        <Box className="basket-summary-item">
                            <Typography>Round Off:</Typography>
                            <Typography>{roundoff.toFixed(2)}</Typography>
                        </Box>
                    )}
                    {getTableId == 0 && itemLevelCharge?.chargeAmt > 0 && (
                        <Box className="basket-summary-item">
                            <Typography>{itemLevelCharge?.chargeName}:</Typography>
                            <Typography>
                                {getStore?.currencysymbol} {itemLevelCharge?.chargeAmt}
                            </Typography>
                        </Box>
                    )}
                    {getTableId == 0 && isDelivery && orderLevelCharge?.chargeAmt > 0 && (
                        <Box className="basket-summary-item">
                            <Typography>{orderLevelCharge?.chargeName}:</Typography>
                            <Typography>
                                {getStore?.currencysymbol} {orderLevelCharge?.chargeAmt}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {/* <Button
                    variant="outlined"
                    className="basket-offer-button"
                    onClick={handleOpenModal}
                >
                    Available Offers{" "}
                    <span className="basket-offer-icon">
                        <ArrowCircleRightIcon sx={{ fontSize: 30 }} />
                    </span>
                </Button> */}
                <p variant="contained" className="basket-total-button">
                    <span className="total-to-pay">Total To Pay: </span> {getStore?.currencysymbol}
                    {totalToPay}
                </p>

                {/* {getTotalDiscount > 0 && (
                    <Button
                        variant="contained"
                        className="basket-total-button"
                        onClick={() => dispatch(removeOffer())}
                    >
                        Remove Offer
                    </Button>
                )} */}
                {getTableId == 0 && (
                    <Box className="swichsetup">
                        <ToggleSwitch handleSwitch={handleSwitch} isDeliveryFlag={isDelivery} />
                    </Box>
                )}
                <Button
                    variant="contained"
                    className="basket-checkout-button"
                    onClick={handleCheckoutClick}
                >
                    <span className="basket-offer-icons">
                        <ArrowCircleRightIcon
                            sx={{ fontSize: 30 }}
                            className="checkout-icon-cart"
                        />
                    </span>{" "}
                    Checkout!
                </Button>
                {/* <Box className="basket-delivery">
                    <RadioGroup row className="basket-delivery-options" defaultValue="pickup">
                        <FormControlLabel
                            value="delivery"
                            control={<Radio />}
                            label="Delivery"
                            onClick={() => setOpenAddress(true)}
                        />
                        <FormControlLabel
                            value="pickup"
                            control={<Radio />}
                            label="Pick up"
                            onClick={() => setOpenAddress(false)}
                        />
                    </RadioGroup>
                    {openAddress && (
                        <TextField
                            label="Enter Your Delivery Address"
                            variant="outlined"
                            fullWidth
                            className="basket-delivery-address"
                        />
                    )}
                    <Button
                        variant="contained"
                        className="basket-submit-button"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box> */}

                {/* <DialogComp openDialog={openDialog} setOpenDialog={setOpenDialog} dialogItem={dialogItem} /> */}
                <OfferModal
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    offerData={offerData}
                    handleOfferClick={handleOfferClick}
                />
            </Box>

            <DrawerComp
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default MyBasket;
