import React from "react";
import dummy from "../../assets/offerImage.png";
import "./OfferScroller.css"; // Import the CSS file

const offers = [
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    {
        title: "Buy 3 get 1 free on Pizza Slices",
    },
    {
        title: "Buy 5 get 3 free on Pizza slices",
    },
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    {
        title: "50% off upto Rs 75/- on orders above Rs.200",
    },
    // Add more offers as needed
];

const OfferScroller = ({ offers }) => {
    return (
        <div>
            <h2 className="offers-title divider line one-line"><span>PROMOS</span></h2>
            <div className="offers-scroller">
                {offers.map((offer, index) => (
                    <div key={index} className="offer-card">
                        <img
                                src={dummy}
                                alt="offer-image"
                                className="offer-image"
                            />
                        <p className="offer-title">{offer.offername}</p>
                        <p className="offer-description">{offer.offerdescription}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OfferScroller;
