import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddToCartPopUp.css";

const AddToCartPopUp = ({ onClose }) => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show the popup when the component mounts
        setIsVisible(true);

        // Set a timer to hide the popup after 4 seconds
        const timer = setTimeout(() => {
            setIsVisible(false);
            setTimeout(() => {
                handleClose(); // Close the popup after the hide animation
            }, 500); // Match the duration of the CSS transition (0.5s)
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const handleViewCart = () => {
        navigate("/cart");
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div className={`cart-popup ${isVisible ? "show" : "hide"}`}>
            <div className="cart-popup-content">
                <span>Item added to Cart</span>
                <button className="view-cart-button" onClick={handleViewCart}>
                    View Cart →
                </button>
                <button className="close-popup-button" onClick={handleClose}>
                    ×
                </button>
            </div>
        </div>
    );
};

export default AddToCartPopUp;
