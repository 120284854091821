import React, {  useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, Divider } from '@mui/material';
import axiosApiFunction from '../../utils/axiosapifunc';
import ErrorContext from '../../context/ErrorContext';
import './OfferModal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
        borderRadius: "20px",
        minWidth: "500px",

      "@media (min-width: 1024px) and (max-width: 1500px)": {
          // width: "60%",
          minWidth: "500px",
      },

      "@media (min-width: 900px) and (max-width: 1024px)": {
          // width: "70%",
          minWidth: "450px",
      },
      
      // Smaller screen settings for even tighter widths
      [theme.breakpoints.down("sm")]: {
          // width: "90%",
          minWidth: "80%",
      },
    },
    
}));

export default function OfferModal({ openDialog, setOpenDialog, offerData, handleOfferClick }) {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {apiError, setApiError, clearApiError}= useContext(ErrorContext);

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  // offerData = []

  // const handleOfferSelect = (offerid) => {
    // clearApiError();
    // console.log('offerid............', offerid);
    // try {
    //   const response = axiosApiFunction( 'post', '/api/v1/applyoffer', { companyid: process.env.REACT_APP_COMPANYID,  locationid: process.env.REACT_APP_LOCATIONID}, process.env.REACT_APP_TOKEN, {

    //   });
    //   console.log('rrrrrrrrrrrr', response)
    //   // setOfferData(response.data)
    // } catch (error) {
    //   setApiError(error.message);
    // } finally {
    //   setIsLoading(false);
    // }
  // }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        className="model-backdrop apply-offers-setup"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Apply Offers
        </DialogTitle>
        <Divider />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ width: '100%', maxWidth: 600, height: 400, overflowY: 'auto', overflowX: 'hidden' }} className="offer-background">
          {offerData?.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, padding: '10px' }}>
              {offerData.map((offer, index) => (
                <ul key={index} className='apply-coupon-div'>
                  <li className='apply-coupon-div'>
                    <div className="model-content-box">
                        <div className='offre-name' component="div" variant="h5">
                          {offer.offername}
                        </div>
                        <div className='offre-content' variant="subtitle1" color="text.secondary" component="div">
                          {offer.offerdescription}
                        </div>
                        <div className='offre-content' variant="subtitle1" color="text.secondary" component="div">
                          {offer.couponcode}
                        </div>
                    </div>
                    <div className="button-box">
                      <Button className="offer-button-model" variant="contained" onClick={() => handleOfferClick(offer)}>Get Offer</Button>
                    </div>
                  </li>
                </ul>
              ))}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '10px' }}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography>No Offers Available Yet</Typography>
              </CardContent>
            </Card>
            </Box>
          )}
        </Box>



        <DialogActions>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
