import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import Logo from "../assets/company-logo.png";
import "./Login.css";
import BuIcon from "../assets/b-arrow.png";
import Footer from "../component/Footer/Footer";
import GetOtp from "../component/Auth/GetOtp";
import StoreLocationPopup from "../component/ModalComp/StoreLocationPopup";
import { useNavigate, useParams } from "react-router-dom";
import LocationContext from "../context/LocationContext";
import ErrorContext from "../context/ErrorContext";
import axiosApiFunction from "../utils/axiosapifunc";
import CryptoJS from 'crypto-js';
import AppStore from '../assets/app-store.png'
import PlayStore from '../assets/play-store.png'

const decryptData = (encryptedData, key) => {
    const ivLength = 16; // AES block size in bytes
    const encryptedBuffer = CryptoJS.enc.Base64.parse(encryptedData);
    
    // Extract the IV from the beginning of the buffer
    const ivWordArray = CryptoJS.lib.WordArray.create(
        encryptedBuffer.words.slice(0, ivLength / 4)
    );
    const ciphertext = CryptoJS.lib.WordArray.create(
        encryptedBuffer.words.slice(ivLength / 4)
    );
    
    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext },
        CryptoJS.enc.Utf8.parse(key),
        { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
};

const parseDecryptedData = (decryptedData) => {
    const params = new URLSearchParams(decryptedData);

    const lid = params.get('lid');
    const cid = params.get('cid');
    //const tblid = params.get('tblid');
    //const tblname = params.get('tblname');
    return { lid, cid };
    //return { lid, cid, tblid, tblname };
};

function LogIn() {
    const navigate = useNavigate();
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [storeError, setStoreError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [selectedLocation, setSelectedLocation] = useState("");
    const [showLocationPopup, setShowLocationPopup] = useState(false);
    const [storesDetail ,setStoresDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const locationCtx = useContext(LocationContext);
    const {apiError, setApiError, clearApiError}= useContext(ErrorContext);
    // const isOTPEnabled = false; //this is static
    const [isOTPEnabled, setIsOTPEnabled] = useState(1);

    const userToken = JSON.parse(localStorage.getItem('tokenData'))?.token;
    const companyId = JSON.parse(localStorage.getItem('companyId'));
    const [parsedData, setParsedData] = useState(null);
    const {id} = useParams();

      const handleOpenLocationPopup = () => {
        setShowLocationPopup(true);  // Open the pop-up when the user clicks the field
    };

    const handleWrongNumberClick = () => {
        setShowOtpInput(false); // Return to the initial input fields
    };

    const handleResendOtp = () => {
        onOtpSendHandle(); // Resend OTP
    };

    const handleLocationSelect = (location) => {
        setSelectedLocation(location);  // Set the selected location
        setStoreError("");
        locationCtx.handleLocationChange(location);
        localStorage.setItem('locationData', JSON.stringify(location));
        setIsOTPEnabled(location?.isotprequired ? location?.isotprequired : 0);
        setShowLocationPopup(false);  // Close the pop-up
    };

    const [formData, setFormData] = useState({
        username: "",
        usermobile: "",
    });

    const [counter, setCounter] = useState(30);

    const handleInputChange = (e) => {
        setNameError('');
        setMobileError('');
        setOtpError('');
        const { name, value } = e.target;
        if (name === 'usermobile') {
            if (/^\d*$/.test(value) && value.length <= 10) {
              setFormData((prevState) => ({ ...prevState, [name]: value }));
            }
          } else {
            setFormData((prevState) => ({ ...prevState, [name]: value }));
          }
    }
    const handleOtp = (otpValue) => {
        setFormData((prevState) => ({ ...prevState, otp: otpValue }));
        setOtpError('');
    };

    const validateInput = (data) => {
        let isValid = true;
        if(!(/^[A-Za-z0-9 ]{3,50}$/.test(data.username))){
            setNameError('Name should be atleast 3 to 50 characters long');
            isValid = false;
        } else {
            setNameError('');
        }

        if(!(/^[6-9]\d{9}$/.test(data.usermobile))){
            setMobileError('Enter valid mobile number');
            isValid = false;
        } else {
            setMobileError('');
        }

        if (!parsedData?.lid && !selectedLocation) {
            setStoreError('Please select a store location');
            isValid = false;
        } else {
            setStoreError('');
        }

        return isValid;
    }

    const handleDirectLogin = async (data) => {
        clearApiError();
        let isValid = true;
        if(!(/^[A-Za-z0-9 ]{3,50}$/.test(data.username))){
            setNameError('Name should be atleast 3 to 50 characters long');
            isValid = false;
        } else {
            setNameError('');
        }

        if(!(/^[6-9]\d{9}$/.test(data.usermobile))){
            setMobileError('Enter valid mobile number');
            isValid = false;
        } else {
            setMobileError('');
        }

        if (!parsedData?.lid && !selectedLocation) {
            setStoreError('Please select a store location');
            isValid = false;
        } else {
            setStoreError('');
        }

        if(!isValid) return;
        if (isLoading) return;
        setIsLoading(true);
        
        try {
            let fetchUserData = formData ? formData : JSON.parse(localStorage.getItem('userData'));

            const response = await axiosApiFunction('post', '/api/v1/login', {}, "", {
                "companyid": companyId,
                "mobileno": formData.usermobile,
                "otp": fetchUserData.otp
            });

            localStorage.setItem('tokenData', JSON.stringify(response.data));
            localStorage.setItem('userData', JSON.stringify(formData));

            const selected = storesDetail.filter((store) => store.locationid == parsedData.lid);

            if(selectedLocation) {
                localStorage.setItem('locationData', JSON.stringify(selectedLocation));
                locationCtx.handleLocationChange(selectedLocation);
            } else {
                localStorage.setItem('locationData', JSON.stringify(selected[0]));
                locationCtx.handleLocationChange(selected[0]);
            }

            navigate('/catalogue');
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onOtpSendHandle = async() => {
        clearApiError();
        if (!validateInput(formData)) {
            return;
        }

        if(isLoading) return;

        setIsLoading(true);

        try {
            console.log(selectedLocation);
            
            const response = await axiosApiFunction('POST', '/api/v1/sendotp', { phoneno: formData.usermobile, locationid: selectedLocation.locationid, companyid: companyId });
            if (response.data.success === 1) {
              setShowOtpInput(true);
              setCounter(30);
            } else {
              setApiError(response.data.msg);
            }
          } catch (error) {
            setApiError(error.message);
          } finally {
            setIsLoading(false);
          }
    }

    const handleSubmit = async() => {
        if(isLoading) return;
        setIsLoading(true);
        let fetchUserData = formData ? formData : JSON.parse(localStorage.getItem('userData'));
        if(formData.otp?.length < 6){
            setOtpError("Please Enter Correct OTP");
            setIsLoading(false);
            return;
        }
        
        if(formData?.usermobile !== fetchUserData?.usermobile){
            localStorage.removeItem('cartData');
        }
        localStorage.removeItem('userData');

        try {
            const response = await axiosApiFunction('post', '/api/v1/verifyotp', { phoneno: fetchUserData.usermobile, otp: fetchUserData.otp });
            if (response.data.success === 1) {
                const tokenResp = await axiosApiFunction('post', '/api/v1/login', {}, "",{ 
                    "companyid": companyId,
                    "mobileno":formData.usermobile,
                    "otp": fetchUserData.otp
                });
                // console.log('tokenrespp>>>>>>>>', tokenResp);
                // if(tokenResp.data.data.phoneno === formData.usermobile){
                    localStorage.setItem('tokenData', JSON.stringify(tokenResp.data));
                    localStorage.setItem('userData', JSON.stringify(formData));
                    localStorage.setItem('locationData', JSON.stringify(selectedLocation));
                    locationCtx.handleLocationChange(selectedLocation);
                    navigate('/catalogue');
                // }
            } else {
                setOtpError(response.data.msg);
                setIsLoading(false);
            }
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    // Encryption function
    let encString = getEncryptedStr('cid=9&lid=68&strid=22')
    function getEncryptedStr(plainText) {
        const iv = CryptoJS.enc.Utf8.parse('9183840118382903');
        const key = CryptoJS.enc.Utf8.parse('ASdis83!8217o9');

        const encrypted = CryptoJS.AES.encrypt(plainText, key, {
            iv: iv,
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.NoPadding
        });

        return encrypted.toString();
    }

    /*  ndsvITuCal01F2lmd5FNJiwMTmrD
        5H1bNX3mqu+XFoqBu9pwwJJz3p7v    */
    const getDecryptedStr11 = (encryptedStr) => {
        // Define the cipher method and key
        const decryptionKey = CryptoJS.enc.Utf8.parse('ASdis83!8217o9');
        const decryptionIv = CryptoJS.enc.Utf8.parse('9183840118382903');

        const encryptedData = CryptoJS.enc.Base64.parse(encryptedStr);

        const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, decryptionKey, {
            iv: decryptionIv,
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.NoPadding,
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
        let timer;
        if (showOtpInput && counter > 0) {
            timer = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        } else if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [showOtpInput, counter]);

    useEffect(() => {
        const key = "ASdis83!8217o9ASdis83!8217o9ASdi"; // Must be 32 bytes for AES-256
        localStorage.setItem("encryptedData", id);
        const encryptedData = id;

        try {
            //const decryptedData = decryptData(encryptedData, key);
            //console.log(decryptedData);
            
            //const parsed = parseDecryptedData(decryptedData);
            //console.log(parsed);
            
            const parsed = { cid: 67, lid: "" };
            setParsedData(parsed);

            if (parsed) {
                console.log(parsed);
                
                if (parsed.cid) {
                    localStorage.setItem("companyId", Number(parsed.cid));
                }
                if (parsed.lid) {
                    localStorage.setItem("locationId", Number(parsed.lid));
                    localStorage.setItem("isLocationAvailable", true);                    
                } else {
                    localStorage.setItem("isLocationAvailable", false);
                }
                if (parsed.tblid) {
                    localStorage.setItem("tblid", Number(parsed.tblid));
                }
                if (parsed.tblname) {
                    localStorage.setItem("tblname", JSON.stringify(parsed.tblname));
                }
            } else {
                console.warn("No parsed data available.");
            }
        } catch (error) {
            console.error("Decryption failed:", error.message);
        }

        async function getStoresDetails() {
            try {
                setIsLoading(true);

                const companyId = JSON.parse(localStorage.getItem('companyId'));

                const storesDetails = await axiosApiFunction(
                    "get",
                    "/api/v1/getbopisstores",
                    { companyid: companyId },
                );
                //console.log(storesDetails.data);
                //setSelectedLocation(storesDetails.data[0]);
                //locationCtx.handleLocationChange(storesDetails.data[0]);
                localStorage.setItem('locationData', JSON.stringify(storesDetails.data[0]));
                setStoresDetail(storesDetails.data);
                setIsOTPEnabled(storesDetails.data[0]?.isotprequired ? storesDetails.data[0]?.isotprequired : 0);
            } catch (error) {
                setApiError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        getStoresDetails();
    }, []);


    return (
        <div className="login-page">
            <div className="main-box">
                <div className="left-part-login">
                    <div className="logo">
                        <img src={Logo} width="100%" />
                    </div>
                    <div className="playstore-links-mainbox">
                        <div className="play-store-img">
                            <a href="https://apps.apple.com/in/app/la-milano-pizzeria/id6467398149">
                                <img src={AppStore} />
                            </a>
                        </div>
                        <div className="app-store-img">
                            <a href="https://play.google.com/store/apps/details?id=delivery.fab.lamilanopizzeria.android">
                                <img src={PlayStore} />
                            </a>
                        </div>
                    </div>
                </div>
                <Box className="box">
                    {!showOtpInput ? (
                        <>
                            <Typography variant="h4" padding={3} textAlign={"center"} className="login-header-text">
                                LET'S START ORDERING
                            </Typography>
                            <TextField
                                fullWidth
                                id="fullWidth"
                                type="text"
                                margin="normal"
                                placeholder="Enter Your Name"
                                name="username"
                                value={formData.username}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ border: "none", outline: "none" }}
                                inputProps={{
                                    autoComplete: "off",
                                }}
                            />
                            <span style={{ color: "red", fontSize: "0.8rem" }}>{nameError}</span>
                            <TextField
                                fullWidth
                                id="fullWidth"
                                type="tel"
                                margin="normal"
                                placeholder="Enter 10 Digit Mobile Number"
                                name="usermobile"
                                inputMode="numeric"
                                sx={{ border: "none", outline: "none" }}
                                inputProps={{
                                    maxLength: 10,
                                    autoComplete: "off",
                                }}
                                value={formData.usermobile}
                                onChange={(e) => handleInputChange(e)}
                            />
                            <span style={{ color: "red", fontSize: "0.8rem" }}>{mobileError}</span>
                            {!(JSON.parse(localStorage.getItem("isLocationAvailable")) == true) && (
                                <>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        placeholder="Select Store Location"
                                        value={selectedLocation.name}
                                        onClick={handleOpenLocationPopup} // Open the pop-up
                                        sx={{ border: "none", outline: "none" }}
                                        InputProps={{
                                            readOnly: true, // Make the field read-only so the user can't type
                                        }}
                                        className="store-input"
                                    />
                                    <span style={{ color: "red", fontSize: "0.8rem" }}>
                                        {storeError}
                                    </span>
                                </>
                            )}

                            <br />
                            {/* {apiError && (
                                <span>
                                    {apiError}
                                    <br />
                                </span>
                            )} */}
                            <Button
                                sx={{
                                    marginTop: 3,
                                    borderRadius: 10,
                                    background: "#FF3A44",
                                }}
                                variant="contained"
                                color="success"
                                onClick={
                                    isOTPEnabled
                                        ? onOtpSendHandle
                                        : () => handleDirectLogin(formData)
                                }
                                 className="login-button-text"
                            >
                                {!isLoading ? (
                                    <span className="login-button-icon">
                                        {isOTPEnabled ? "Send OTP" : "Start Ordering"}{" "}
                                        <img src={BuIcon} alt="BuIcon" />
                                    </span>
                                ) : (
                                    <CircularProgress color="inherit" />
                                )}
                            </Button>
                        </>
                    ) : (
                        <div>
                            <Typography variant="h4" padding={3} textAlign={"center"}>
                                ENTER YOUR OTP
                            </Typography>
                            {selectedLocation?.name && (
                                <Typography
                                    variant="body1"
                                    padding={2}
                                    textAlign={"center"}
                                    color="primary"
                                    sx={{ color: "gray", fontWeight: "600" }}
                                >
                                    {selectedLocation?.name}
                                </Typography>
                            )}
                            <GetOtp length={6} onOtpChange={handleOtp} />
                            <span style={{ color: "red" }}>{otpError}</span>
                            <br />
                            <Button
                                sx={{
                                    marginTop: 3,
                                    borderRadius: 10,
                                    background: "#FF3A44",
                                }}
                                variant="contained"
                                color="success"
                                onClick={handleSubmit}
                            >
                                {!isLoading ? (
                                    <span>
                                        Submit <img src={BuIcon} />
                                    </span>
                                ) : (
                                    <CircularProgress color="inherit" />
                                )}
                            </Button>
                            <Typography
                                variant="body2"
                                color="primary"
                                sx={{ marginTop: 2, cursor: "pointer" }}
                                onClick={handleWrongNumberClick}
                            >
                                Wrong number?
                            </Typography>
                            {counter > 0 ? (
                                <Typography variant="body2" sx={{ marginTop: 2 }}>
                                    Resend OTP in: {counter}s
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    sx={{ marginTop: 2, cursor: "pointer" }}
                                    onClick={handleResendOtp}
                                >
                                    Resend OTP
                                </Typography>
                            )}
                        </div>
                    )}
                </Box>
            </div>
            <StoreLocationPopup
                open={showLocationPopup}
                onClose={() => setShowLocationPopup(false)} // Close the pop-up
                onSelectLocation={handleLocationSelect} // Handle location selection
                locations={storesDetail}
            />
            <Footer />
        </div>
    );
}

export default LogIn;
