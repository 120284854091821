import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomePage from "./pages/Home";
import LogIn from "./pages/LogIn";
import CartPage from "./pages/CartPage";
import PaymentPage from "./pages/PaymentPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentFailedPage from "./pages/PaymentFailedPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import RefundAndCancellation from "./pages/RefundAndCancellation";
import AboutUs from "./pages/AboutUs";
import { OfferContextProvider } from "./context/OfferContext";
import { LocationContextProvider } from "./context/LocationContext";
import { ErrorContextProvider } from "./context/ErrorContext";
import ProtectedRoute from "./ProtectedRoute";
import ServerNotReachablePage from "./pages/ServerNotReachablePage";
import PageNotFound from "./pages/PageNotFound";
import OrderHistory from "./pages/OrderHistory";
import OrderDetails from "./pages/OrderDetails";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000", // Set the primary color
        },
        secondary: {
            main: "#ff0000", // Set the secondary color
        },
    },
});

function App() {
    const isLoggedIn = () => {
        return localStorage.getItem("tokenData") !== null;
    };

    return (
        <div>
            <ErrorContextProvider>
                <OfferContextProvider>
                    <LocationContextProvider>
                        <ThemeProvider theme={theme}>
                            <BrowserRouter>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate to={isLoggedIn() ? "/catalogue" : "/login"} />
                                        }
                                    />
                                    <Route
                                        //path="/login/:id"
                                        path="/login"
                                        element={
                                            isLoggedIn() ? <Navigate to="/catalogue" /> : <LogIn />
                                        }
                                    />
                                    <Route
                                        path="/catalogue"
                                        element={<ProtectedRoute element={<HomePage />} />}
                                    />
                                    <Route
                                        path="/cart"
                                        element={<ProtectedRoute element={<CartPage />} />}
                                    />
                                    <Route
                                        path="/order-history"
                                        element={<ProtectedRoute element={<OrderHistory />} />}
                                    />
                                    <Route
                                        path="/order-history/:orderid"
                                        element={<ProtectedRoute element={<OrderDetails />} />}
                                    />
                                    <Route
                                        path="/payment"
                                        element={<ProtectedRoute element={<PaymentPage />} />}
                                    />
                                    <Route
                                        path="/payment-success"
                                        element={
                                            <ProtectedRoute element={<PaymentSuccessPage />} />
                                        }
                                    />
                                    <Route
                                        path="/payment-failed"
                                        element={<ProtectedRoute element={<PaymentFailedPage />} />}
                                    />
                                    <Route
                                        path="/server-not-reachable"
                                        element={
                                            <ProtectedRoute element={<ServerNotReachablePage />} />
                                        }
                                    />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                    <Route
                                        path="/terms-and-condition"
                                        element={<TermsAndCondition />}
                                    />
                                    <Route
                                        path="/refund-and-cancellation"
                                        element={<RefundAndCancellation />}
                                    />
                                    <Route
                                        path="/about-us"
                                        element={<AboutUs />}
                                    />
                                    <Route path="/*" element={<PageNotFound />} />
                                </Routes>
                            </BrowserRouter>
                        </ThemeProvider>
                    </LocationContextProvider>
                </OfferContextProvider>
            </ErrorContextProvider>
        </div>
    );
}

export default App;
