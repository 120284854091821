import React from "react";
import { SpinnerCircular } from "spinners-react";
import "./Spinner.css";
 
function Spinner({ enabled }) {
    return (
        <>
            {enabled && (
                <div className="Backdrop">
                    <SpinnerCircular
                        size={100}
                        thickness={98}
                        speed={180}
                        color="rgba(255, 255, 255, 255)"
                        secondaryColor="var(--links)"
                    />
                </div>
            )}
        </>
    );
}
 
export default Spinner;