import axios from 'axios';

const axiosApiFunction = (method, endpoint, paramsobj, token, body = null) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: `${process.env.REACT_APP_BACKEND_BASEURL}${endpoint}`,
      params: paramsobj,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Adjust as needed
        'Accept': 'application/json'
      },
      data: body, // Include body in the request
    })
      .then(response => {
        resolve(response); // Resolve with the response data
      })
      .catch(error => {
        reject(error); // Reject with the error
      });
  });
};

export default axiosApiFunction;
