// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, ...rest }) => {
    const isLoggedIn = () => {
        // Check if the user is logged in (e.g., check for a token in localStorage)
        return localStorage.getItem("tokenData") !== null
    };

    return isLoggedIn() ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
