import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Link, useMediaQuery } from "@mui/material";
import "./Footer.css";

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:850px)');
    const navigate = useNavigate();

    const handleAboutUs = () => {
      navigate("/about-us")
    }

    const handlePrivacyPolicyClick = () => {
      navigate("/privacy-policy");
    }
    
    const handleTermsAndService = () => {
      navigate("/terms-and-condition");
    }

    const handleRefundAndCancellation  = () => {
      navigate("/refund-and-cancellation");
    }

  return (
    <Box className={`footer ${isMobile ? 'footer-mobile' : ''}`}>
      <Typography variant="body2" className="footer-text">
        &copy; 2024 All Rights Reserved by MFW FOODS PRIVATE LIMITED
      </Typography>
      <Box className={`footer-links ${isMobile ? 'footer-links-mobile' : ''}`}>
        <Link color="inherit" className="footer-link" onClick={handleAboutUs}>
          About Us
        </Link>
        <Link color="inherit" className="footer-link" onClick={handlePrivacyPolicyClick}>
          Privacy Policy
        </Link>
        <Link color="inherit" className="footer-link" onClick={handleTermsAndService}>
          Terms Of Service
        </Link>
        <Link color="inherit" className="footer-link" onClick={handleRefundAndCancellation}>
          Refund & Cancellation
        </Link>
      </Box>
    </Box>
  
  );
}

export default Footer;
