import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../component/Header/Header";
import Footer from "../component/Footer/Footer";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import animationData from "../assets/page-not-found.lottie";
import { Box, Container, Grid, Typography, Paper, Button } from "@mui/material";
import "./PageNotFound.css";
import Snr from "../assets/pnf.webp";

function PageNotFound() {
    const navigate = useNavigate();

    const onMenuClick = () => {
        navigate("/catalogue");
    };

    return (
        <div>
            {/* <Header /> */}
            <Container className="payment-root">
                <Paper elevation={3} className="ps-order-error-container">
                    <Box className="ps-icon-container">
                        {/* <ErrorIcon className="pf-error-icon" /> */}
                        {/* <img src={Snr} alt="Logo" className="set-snr-img" /> */}
                        <DotLottieReact src={animationData} loop autoplay />
                    </Box>
                    <Button
                        variant="contained"
                        color="success"
                        className="ps-go-to-menu-button"
                        onClick={onMenuClick}
                    >
                        Go to Menu Page
                    </Button>
                </Paper>
            </Container>
            <Footer />
        </div>
    );
}

export default PageNotFound;
