import React from "react";
import { Navigate } from "react-router-dom";
import "./ServerNotReachablePage.css";
import Header from "../component/Header/Header";
import { Box, Container, Grid, Typography, Paper, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import Snr from "../assets/snr.webp";

function ServerNotReachablePage() {
    const navigate = Navigate();

    const handleHomeClick = () => {
        navigate('/catalogue');
    };

    return (
        <div>
            <Header />
            <Container className="payment-root">
                <Paper elevation={3} className="ps-order-error-container">
                    <Box className="ps-icon-container">
                        {/* <ErrorIcon className="pf-error-icon" /> */}
                        <img src={Snr} alt="Logo" className="set-snr-img" />
                    </Box>
                    <Typography variant="h5" className="ps-title">
                        Oops Server Is Not Reachable
                    </Typography>

                    <Button
                        variant="contained"
                        color="success"
                        className="ps-go-to-menu-button"
                        onClick={handleHomeClick}
                    >
                        Go to Menu Page
                    </Button>
                </Paper>
            </Container>
        </div>
    );
}

export default ServerNotReachablePage;
