import React, { useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import dummy from "../../assets/DummyImage.png";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "./Sidebar.css";

const Sidebar = ({ onSelectCategory, categorydata, selectedCategory, categoryRefs }) => {
    const [open, setOpen] = useState(false);
    const [openSubcategories, setOpenSubcategories] = useState({});

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggleSubcategory = (categoryId) => {
        setOpenSubcategories((prevState) => ({
            // ...prevState,
            [categoryId]: !prevState[categoryId],
        }));
    };

    return (
        <>
            <Box className="sidebar">
                <Box className="sidebar-header">
                    <ListItemIcon className="sidebar-icon">
                        <MenuBookIcon />
                    </ListItemIcon>
                    <Typography variant="h6" className="sidebar-title">
                        Category
                    </Typography>
                </Box>
                <List component="nav">
                    {categorydata.map((item, index) => (
                        <React.Fragment key={index}>
                            {(item.items.length > 0 || (item.subCategories.length > 0 && item.subCategories.some(sub => sub.items.length > 0))) && 
                                <ListItem
                                    button
                                    className={
                                        selectedCategory
                                            ? item.refId === selectedCategory
                                                ? "sidebar-item active"
                                                : "sidebar-item"
                                            : index === 0
                                            ? "sidebar-item active"
                                            : "sidebar-item"
                                    }
                                    onClick={() => {
                                        onSelectCategory(item.refId);
                                        handleClose(); // Close sidebar on item click (for mobile)
                                    }}
                                >
                                    <img
                                        src={
                                            item.imgUrl && item.imgUrl !== null && item.imgUrl !== ""
                                                ? item.imgUrl
                                                : dummy
                                        }
                                        onError={(e) => {
                                            e.target.src = dummy;
                                        }}
                                        alt={item.title}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            marginRight: "10px",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    <ListItemText primary={item.title} className="nav-category-list"/>
                                    {item.subCategories && item.subCategories.some(sub => sub.items.length > 0) ? (
                                        openSubcategories[item.refId] ? (
                                            <ExpandLess
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering ListItem onClick
                                                    handleToggleSubcategory(item.refId);
                                                }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering ListItem onClick
                                                    handleToggleSubcategory(item.refId);
                                                }}
                                            />
                                        )
                                    ) : null}
                                </ListItem>
                            }
                            {item.subCategories && item.subCategories.length > 0 && (
                                <Collapse
                                    in={openSubcategories[item.refId]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {item.subCategories.map((subItem, index) => (
                                            <>
                                                {subItem.items.length > 0 && (
                                                    <ListItem
                                                        button
                                                        key={index}
                                                        className={
                                                            selectedCategory === subItem.refId
                                                                ? "sidebar-item active"
                                                                : "sidebar-item"
                                                        }
                                                        onClick={() => {
                                                            onSelectCategory(subItem.refId);
                                                            handleClose(); // Close sidebar on item click (for mobile)
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={subItem.title}
                                                            className="nav-category-list"
                                                            style={{
                                                                marginLeft: "20px",
                                                                fontSize: "small",
                                                            }}
                                                        />
                                                    </ListItem>
                                                )}
                                            </>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </Box>

            {/* Modal Dialog for Mobile */}
            <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle className="sidebar-header">
                    <ListItemIcon className="sidebar-icon">
                        <MenuBookIcon />
                    </ListItemIcon>
                    <Typography variant="h6" className="sidebar-title">
                        Category
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List component="nav">
                        {categorydata.map((item, index) => (
                            <React.Fragment key={index}>
                                 {(item.items.length > 0 || item.subCategories.length > 0) && 
                                    <ListItem
                                        button
                                        className={
                                            selectedCategory
                                                ? item.refId === selectedCategory
                                                    ? "sidebar-item active"
                                                    : "sidebar-item"
                                                : index === 0
                                                ? "sidebar-item active"
                                                : "sidebar-item"
                                        }
                                        onClick={() => {
                                            onSelectCategory(item.refId);
                                            handleClose(); // Close sidebar on item click (for mobile)
                                        }}
                                    >
                                        <img
                                            src={
                                                item.imgUrl &&
                                                item.imgUrl !== null &&
                                                item.imgUrl !== ""
                                                    ? item.imgUrl
                                                    : dummy
                                            }
                                            onError={(e) => {
                                                e.target.src = dummy;
                                              }}
                                            alt={item.title}
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                marginRight: "10px",
                                                borderRadius: "5px",
                                            }}
                                        />
                                        <ListItemText primary={item.title} className="nav-category-list" />
                                        {item.subCategories && item.subCategories.some(sub => sub.items.length > 0) ? (
                                            openSubcategories[item.refId] ? (
                                                <ExpandLess
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering ListItem onClick
                                                        handleToggleSubcategory(item.refId);
                                                    }}
                                                />
                                            ) : (
                                                <ExpandMore
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering ListItem onClick
                                                        handleToggleSubcategory(item.refId);
                                                    }}
                                                />
                                            )
                                        ) : null}
                                    </ListItem>
                                }
                                {item.subCategories && item.subCategories.length > 0 && (
                                    <Collapse
                                        in={openSubcategories[item.refId]}
                                        timeout="auto"
                                        unmountOnExit
                                        key={index}
                                    >
                                        <List component="div" disablePadding>
                                            {item.subCategories.map((subItem, index) => (
                                                <ListItem
                                                    button
                                                    key={index}
                                                    className={
                                                        selectedCategory === subItem.refId
                                                            ? "sidebar-item active"
                                                            : "sidebar-item"
                                                    }
                                                    onClick={() => {
                                                        onSelectCategory(subItem.refId);
                                                        handleClose(); // Close sidebar on item click (for mobile)
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={subItem.title}
                                                        className="nav-category-list"
                                                        style={{
                                                            marginLeft: "20px",
                                                            fontSize: "small",
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Button to Open Sidebar Modal (Mobile Only) */}
            <Button
                variant="contained"
                color="primary"
                className="sidebar-toggle-button"
                onClick={handleOpen}
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                }} // Show only on mobile screens
            >
                Open Categories
            </Button>
        </>
    );
};

export default Sidebar;
