export let categories = [];
export let subCategories = [];
export let items = [];
export let optionGroups = [];
export let options = [];
export let taxes = [];

export default function bifurcateCategoriesAndItems(categoriesJson, itemsJson, optionGroupsJson, optionsJson) {
  categories = [];
  subCategories = [];
  items = [];
  optionGroups = [];
  options = [];
  taxes = [];
  // Parse Categories and Subcategories
  categoriesJson.forEach(item => {
    if (!item.parent_ref_id) {
      categories.push(Category.fromJson(item));
    } else {
      subCategories.push(SubCategory.fromJson(item));
    }
  });
  
  categories.sort((a, b) => {
    return Number(a.sort_order) - Number(b.sort_order)
  })

  // Parse Option Groups
  optionGroups = optionGroupsJson.map((json)=>{
    return OptionGroup.fromJson(json, []);
  });
  // Parse Options
  options = optionsJson.map(json => Option.fromJson(json));

  // Update Option Groups with associated Options
  optionGroups.forEach(optionGroup => {
    optionGroup.options.push(...options.filter(opt => opt.optGroupRefIds.includes(optionGroup.refId)));
    optionGroup.options.sort((a, b) => a.sortOrder - b.sortOrder);
  });

  optionGroups.forEach(OG=>{
    OG.options.forEach(o=>{
      o.groupRefID=OG.refId;
      o.groupTitle=OG.title;
    });
  });

  // Parse Items
  itemsJson.forEach(item => {
    var itemOptionGroups = [];

    // Filter option groups to find the ones associated with this item
    
    for (var optionGroup in optionGroups) {
      if (optionGroups[optionGroup].item_ref_ids.includes(item.ref_id)) {
        itemOptionGroups.push(optionGroups[optionGroup]);
        // optionGroups.splice(optionGroup, 1);
      }
    }
    // items.push(Item.fromJson(item, itemOptionGroups));
    // Assign taxes to items
    let itemTaxes = taxes.filter(tax => tax.item_ref_ids.includes(item.ref_id));

    // Add item with associated option groups and taxes
    items.push(Item.fromJson(item, itemOptionGroups, itemTaxes));
  });


  // Assign subcategories to their respective parent categories
  categories.forEach(category => {
    category.subCategories.push(...subCategories.filter(sub => sub.parentRefId === category.refId));
    category.subCategories.sort((a, b) => (a.sortOrder ?? 999) - (b.sortOrder ?? 999));
  });

  // Assign items to their respective categories or subcategories
  items.forEach(item => {
    categories.forEach(category => {
      if (item.categoryRefIds.includes(category.refId)) {
        category.items.push(item);
      }
      category.subCategories.forEach(subCategory => {
        if (item.categoryRefIds.includes(subCategory.refId)) {
          subCategory.items.push(item);
        }
      });
    });
  });

  // categories.forEach(category => {
  //   console.log(`Category: ${category.title}`);
    
  //   if (category.subCategories) {
  //     category.subCategories.forEach(subCategory => {
  //       console.log(`  SubCategory: ${subCategory.title}`);
        
  //       if (subCategory.items) {
  //         subCategory.items.forEach(item => {
  //           console.log(`  Item: ${item.title} -ID : ${item.refId}   - available: ${item.available}`);
            
  //           if (item.optionGroupRefs) {
  //             item.optionGroupRefs.forEach(optionGroup => {
  //               console.log(`      Option Group: ${optionGroup.title} -ID : ${optionGroup.refId}    - Sort Order: ${optionGroup.sortOrder}`);
                
  //               if (optionGroup.options) {
  //                 optionGroup.options.forEach(option => {
  //                   console.log(`        Option: ${option.title} -ID : ${option.refId}    - available: ${option.available}`);
  //                 });
  //               }
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
    
  //   if (category.items) {
  //     category.items.forEach(item => {
  //       console.log(`  Item: ${item.title} -ID : ${item.refId}   - available: ${item.available}`);
        
  //       if (item.optionGroupRefs) {
  //         item.optionGroupRefs.forEach(optionGroup => {
  //           console.log(`      Option Group: ${optionGroup.title} -ID : ${optionGroup.refId}    - Sort Order: ${optionGroup.sortOrder}`);
            
  //           if (optionGroup.options) {
  //             optionGroup.options.forEach(option => {
  //               console.log(`        Option: ${option.title} -ID : ${option.refId}    - available: ${option.available}`);
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  // });
  
}


class Category {
  constructor(refId, title,imgUrl,description,sort_order,active,subCategories = [], items = []) {
    this.refId = refId;
    this.title = title;
    this.subCategories = subCategories;
    this.items = items;
    this.imgUrl=imgUrl;
    this.description=description;
    this.sort_order=sort_order;
    this.active=active;
  }

  static fromJson(json) {
    return new Category(json.ref_id, json.name,json.img_url, json.description,json.sort_order,json.active);
  }
}

class SubCategory {
  constructor(refId, title, parentRefId, items = [], sortOrder,active, imgUrl) {
    this.refId = refId;
    this.title = title;
    this.parentRefId = parentRefId;
    this.items = items;
    this.sortOrder = sortOrder;
    this.active = active;
    this.imgUrl = imgUrl || null;
  }

  static fromJson(json) {
    return new SubCategory(
      json.ref_id,
      json.name,
      json.parent_ref_id,
      [],
      json.sort_order ? parseInt(json.sort_order, 10) : 999,
      json.active,
      json.img_url
    );
  }
}

class Item {
  constructor(refId, title, categoryRefIds, available, imgUrl, description, weight, soldAtStore, sortOrder, price, markupPrice, currentStock, recommended, foodType, optionGroups = []) {
    this.refId = refId;
    this.title = title;
    this.categoryRefIds = categoryRefIds || [];
    this.available = available || false;
    this.imgUrl = imgUrl || null;
    this.description = description || null;
    this.weight = weight || null;
    this.soldAtStore = soldAtStore || null;
    this.sortOrder = sortOrder || 999;
    this.price = price || 0;
    this.markupPrice = markupPrice || 0;
    this.currentStock = currentStock || 0;
    this.recommended = recommended || false;
    this.foodType = foodType || null;
    this.optionGroups = optionGroups || [];
  }

  static fromJson(json, optionGroups) {
    return new Item(
      json.ref_id,
      json.title,
      json.category_ref_ids,
      json.available,
      json.img_url,
      json.description,
      json.weight,
      json.sold_at_store,
      json.sort_order,
      json.price,
      json.markup_price,
      json.current_stock,
      json.recommended,
      json.food_type,
      optionGroups
    );
  }

  toJson() {
    return {
      ref_id: this.refId,
      title: this.title,
      category_ref_ids: this.categoryRefIds,
      available: this.available,
      img_url: this.imgUrl,
      description: this.description,
      weight: this.weight,
      sold_at_store: this.soldAtStore,
      sort_order: this.sortOrder,
      price: this.price,
      markup_price: this.markupPrice,
      current_stock: this.currentStock,
      recommended: this.recommended,
      food_type: this.foodType,
      option_groups: this.optionGroups.map(optionGroup => optionGroup.toJson()),
    };
  }
}

class OptionGroup {
  constructor(refId, title, refTitle, minSelectable, maxSelectable, active, options = [], sortOrder,item_ref_ids=[]) {
    this.refId = refId;
    this.title = title;
    this.refTitle = refTitle;
    this.minSelectable = minSelectable;
    this.maxSelectable = maxSelectable;
    this.active = active;
    this.options = options;
    this.sortOrder = sortOrder;
    this.item_ref_ids = item_ref_ids;
  }

  static fromJson(json, groupOptions) {
    return new OptionGroup(
      json.ref_id,
      json.title,
      json.ref_title,
      json.min_selectable,
      json.max_selectable,
      json.active,
      groupOptions,
      json.sort_order ? parseInt(json.sort_order, 10) : 999,
      json.item_ref_ids
    );
  }
}

class Option {
  constructor(refId, title, description, weight, available, price, markupPrice, soldAtStore, sortOrder, optGroupRefIds = [], nestedOptGroups = [], foodType = "") {
    this.refId = refId;
    this.title = title;
    this.description = description;
    this.weight = weight;
    this.available = available;
    this.price = price;
    this.markupPrice = markupPrice;
    this.soldAtStore = soldAtStore;
    this.sortOrder = sortOrder;
    this.optGroupRefIds = optGroupRefIds;
    this.nestedOptGroups = nestedOptGroups;
    this.foodType = foodType;
  }

  static fromJson(json) {
    return new Option(
      json.ref_id,
      json.title,
      json.description || "",
      json.weight ? parseFloat(json.weight) : 0.0,
      json.available === true || json.available === false ? json.available : (json.available === "true" || json.available === "false" ? (json.available === "true" ? true : false) : (json.available === 1 || json.available === "1" ? true : false)),
      json.price ? parseFloat(json.price) : 0.0,
      json.markup_price ? parseFloat(json.markup_price) : 0.0,
      json.sold_at_store || false,
      json.sort_order ? parseInt(json.sort_order, 10) : 999,
      json.opt_grp_ref_ids || [],
      json.nested_opt_grps || [],
      json.food_type || ""
    );
  }
}

