// FreeDeliveryNotification.js
import React from 'react';
import './OfferAppliedNotification.css';
import { useSelector } from 'react-redux';

const OfferAppliedNotification = () => {
  const getTotalDiscount = useSelector((state) => state.cart?.totalDiscount);
  const getStoreData = useSelector((state) => state.store.storeData)

  return (
    <div className="notification">
      <div className="icon">{getStoreData?.currencysymbol}</div>
      <div className="text-content">
        <div className="title">Yay! Offer Applied</div>
        <div className="subtitle">You saved <span className="highlight">{getStoreData?.currencysymbol} {getTotalDiscount}</span> on your order</div>
      </div>
    </div>
  );
}

export default OfferAppliedNotification;
