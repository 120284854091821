import React, { useContext, useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import dummy from "../../assets/DummyImage.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Divider, Paper, Chip } from "@mui/material";
import ErrorContext from "../../context/ErrorContext";
import "./SearchModal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "20px",  // Apply border-radius to the dialog container
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
        width: "460px",
    },
}));

export default function SearchModal({
    openSearchDialog,
    setOpenSearchDialog,
    searchItems,
    addProductToCart,
    isStoreOpen,
}) {
    const [open, setOpen] = useState(false);
    const searchInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState("");
    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);
    const getStore = useSelector((state) => state.store?.storeData);

    useEffect(() => {
        setOpen(openSearchDialog);
        setSearchQuery("");
    }, [openSearchDialog]);

    useEffect(() => {
        if (open) {
            searchInputRef.current.focus();
        }
    }, [open]);

    const handleClose = () => {
        setOpenSearchDialog(false);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredItems = searchItems.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleProductClick = (item) => {
        if ((item.available == 1 || item.available == true) && isStoreOpen) {
            setOpenSearchDialog(false);
            addProductToCart(
                item,
                item.price
                    ? item.price
                    : item?.optionGroups.find(
                          (el) => el.item_ref_ids == item.refId
                      )?.options[0].price
            );
        } else {
            return;
        }
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openSearchDialog}
                className="model-backdrop search-model-setup sm-main-model customised-dialogue-sm"
            >
                <DialogTitle sx={{ m: 1 }} id="customized-dialog-title customised-search-sm sm-title-product">
                    Product Search
                </DialogTitle>
                <Divider />
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        padding: 2,
                        border: "1px solid black",
                        borderRadius: 2,
                    }}
                    className="product-search-main"
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search for items and more..."
                        value={searchQuery}
                        onChange={handleSearch}
                        inputRef={searchInputRef}
                        inputProps={{
                            maxLength: 40,
                            autoComplete: "off",
                         }}
                    />
                </Box>
                <Box
                    className="search-model-width"
                    sx={{
                        width: "100%",
                        maxWidth: 600,
                        height: 400,
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: 2,
                    }}
                >
                    {filteredItems.length > 0 ? (filteredItems.map((item) => (
                        // <Box
                        //     key={item.title}
                        //     sx={{
                        //         display: "flex",
                        //         alignItems: "center",
                        //         padding: 1,
                        //         borderBottom: "1px solid #ddd",
                        //     }}
                        //     onClick={() => handleProductClick(item)}
                        // >
                        //     <img
                        //         src={item.imgUrl}
                        //         alt={item.title}
                        //         style={{ width: 50, height: 50, marginRight: 10 }}
                        //     />
                        //     <Typography variant="body1">{item.title}</Typography>
                        // </Box>
                        <div
                            className="search-product-margin"
                            onClick={() => handleProductClick(item)}
                        >
                            <div className="pro-titel-img-box">
                                <div>
                                    <img
                                        src={
                                            item.imgUrl &&
                                            item.imgUrl !== null &&
                                            item.imgUrl !== ""
                                                ? item.imgUrl
                                                : dummy
                                        }
                                        onError={(e) => {
                                            e.target.src = dummy;
                                        }}
                                        alt={item.title}
                                        className="product-image"
                                    />
                                </div>
                                <div className="set-nv">
                                    <div>
                                    <div className={item.foodType == 1 ? "veg-flag" : "non-vegflag"}>
                                            <span></span>
                                        </div>
                                    </div>
                                
                                    <div className="content-box-set">
                                        <Typography
                                            variant="h6"
                                            className="product-title search-item-title"
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            className="product-prices search-item-price"
                                        >
                                            {getStore?.currencysymbol} {" "}
                                            {item.price
                                                ? item.price
                                                : item?.optionGroups.find(
                                                    (el) =>
                                                        el.item_ref_ids ==
                                                        item.refId
                                                )?.options[0].price}
                                        </Typography>
                                        <div className="customizable-name">
                                            {item.optionGroups.length > 0 && (
                                                <Chip
                                                    label="customize"
                                                    sx={{
                                                        borderColor: "green",
                                                        color: "green",
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            {(item.available != 1 ||
                                                item.available != true) && (
                                                <Chip
                                                    label="Not available"
                                                    color="error"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* {Object.keys(getStore).length > 0 &&
                                    getStore.active == "true" &&
                                    isStoreOpen(getStore.timings) && (
                                        <div className="product-actions">
                                            {item.optionGroups.length > 0 ? (
                                                <Chip
                                                    label="customizable"
                                                    sx={{
                                                        borderColor: "green",
                                                        color: "green",
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            ) : (
                                                <div></div>
                                            )}
                                            {item.available != 1 ||
                                            item.available != true ? (
                                                <Chip
                                                    label="Not available"
                                                    color="error"
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            ) : (
                                                <IconButton
                                                    color="primary"
                                                    className="add-button"
                                                    onClick={() =>
                                                        handleProductClick(item)
                                                    }
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            )}
                                        </div>
                                    )} */}
                            </div>
                        </div>
                    ))) : (
                        <div className="no-search-found">
                           <Typography variant="body2">
                            {`No Result Found For `}
                                <span className="no-search-found-span">"{searchQuery}"</span>
                            </Typography>
                        </div>
                    )}
                </Box>
                <DialogActions></DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
