import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import animationData from "../assets/payment-success.lottie";
import "./PaymentSuccessPage.css";
import Header from "../component/Header/Header";
import { Box, Container, Grid, Typography, Paper, Button } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Pspo from "../assets/pspo.webp";

function PaymentSuccessPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const uniqueKey = location.state?.uniqueKey;

    const offerData = {
        offername: "",
        offerdescription: "",
        isofferapplied: false
    }

    localStorage.setItem('offerData', JSON.stringify(offerData));

    const handleMenuClick = () => {
        navigate("/catalogue");
    };

    const handleTrackOrder = (uniqueKey) => {
        navigate(`/order-history/${uniqueKey}`);
    }

    useEffect(() => {
        if (!uniqueKey) {
            navigate("/catalogue");
        }
    }, [uniqueKey]);

    return (
        <div>
            <Header />
            <Container className="payment-root">
                <Paper elevation={3} className="ps-order-error-container">
                    <Box className="ps-icon-container">
                        {/* <CheckCircleIcon className="ps-success-icon"/> */}
                        {/* <img src={Pspo} alt="Logo" className="set-snr-img" /> */}
                        <DotLottieReact src={animationData} loop autoplay />
                        {/* <CheckCircleIcon className="ps-success-icon"/> */}
                        {/* <img src={Pspo} alt="Logo" className="set-snr-img" /> */}
                    </Box>
                    <Typography variant="h5" className="ps-title">
                        Your Order has been Placed!
                    </Typography>
                    <Typography variant="body1" className="ps-message">
                        Thank you for your order. Your order number is
                    </Typography>
                    <Typography variant="body1" className="payment-success-od">
                        <span>{uniqueKey}</span>
                    </Typography>
                    <Typography variant="body1" className="ps-next-steps">
                        Your food will be with you shortly. We hope you enjoy your meal!
                    </Typography>
                    <Typography variant="body1" className="ps-next-steps">
                        You can now track your order or return to the menu.
                    </Typography>
                    <div className="button-block">
                        <Button variant="contained" color="success" className="ps-go-to-menu-button" onClick={handleMenuClick}>
                            <MenuBookIcon/> &nbsp;&nbsp;Go to Menu Page
                        </Button>
                        <Button variant="contained" color="success" className="ps-go-to-menu-button" onClick={()=>handleTrackOrder(uniqueKey)}>
                           <GpsFixedIcon/> &nbsp;&nbsp; Track Order
                        </Button>
                    </div>
                </Paper>
            </Container>
        </div>
    );
}

export default PaymentSuccessPage;
