import { React, useState, createContext } from "react";
 
const ErrorContext = createContext({
    apiError: null,
    setApiError: (errorMessage) => {},
    clearApiError: () => {},
});
 
export const ErrorContextProvider = (props) => {
    const [apiError, setApiError] = useState(null);
 
    const handleSetApiError = (errorMessage) => {
        setApiError(errorMessage);
    };
 
    const handleClearApiError = () => {
        setApiError(null);
    };
 
    const contextValue = {
        apiError: apiError,
        setApiError: handleSetApiError,
        clearApiError: handleClearApiError,
    };
 
    return <ErrorContext.Provider value={contextValue}>{props.children}</ErrorContext.Provider>;
};
 
export default ErrorContext;