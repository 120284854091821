import { React, useState, createContext } from "react";

const LocationContext = createContext({
    storeLocation: {},
    handleLocationChange: (location) => {},
});

export const LocationContextProvider = (props) => {
    const [selectedLocation, setSelectedLocation] = useState({});

    const handleLocationChange = (location) => {
        setSelectedLocation(location);
    };

    const contextValue = {
        storeLocation: selectedLocation,
        handleLocationChange: handleLocationChange
    };

    return <LocationContext.Provider value={contextValue}>{props.children}</LocationContext.Provider>;
};

export default LocationContext;
