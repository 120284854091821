// DiscountCard.js
import React from 'react';
import './DiscountCard.css';

const DiscountCard = () => {
  return (
    <div className="card">
      <div className="code">
        <div className="icon">%</div>
        <div className="code-text">LPN200</div>
      </div>
      <div className="description">
        Get Flat Discount of Rs.200 on Minimum Billing of Rs.999.
      </div>
      <div className="actions">
        <a href="#" className="apply-link">Apply</a>
        <a href="#" className="more-offers">View more offers</a>
      </div>
    </div>
  );
}

export default DiscountCard;
