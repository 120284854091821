import { React, useState, createContext } from "react";

const OfferContext = createContext({
    appliedOfferName: "",
    appliedOfferDescription: "",
    isOfferApplied: false,
    handleOfferNameChange: (offerName) => {},
    handleOfferDescriptionChange: (offerDescription) => {},
    handleOffer: (offerApply) => {},
    resetOffer: () => {},
});

export const OfferContextProvider = (props) => {
    const [appliedOfferName, setAppliedOfferName] = useState("");
    const [appliedOfferDescription, setAppliedOfferDescription] = useState("");
    const [isOfferApplied, setIsOfferApplied] = useState(false);

    const handleOfferNameChange = (appliedOfferName) => {
        setAppliedOfferName(appliedOfferName);
    };

    const handleOfferDescriptionChange = (appliedOfferDescription) => {
        setAppliedOfferDescription(appliedOfferDescription);
    };

    const handleOffer = (isOfferApplied) => {
        setIsOfferApplied(isOfferApplied);
    };

    const resetOffer = () => {
        setAppliedOfferName("");
        setAppliedOfferDescription("");
        setIsOfferApplied(false);
    };

    const contextValue = {
        appliedOfferName: appliedOfferName,
        appliedOfferDescription: appliedOfferDescription,
        isOfferApplied: isOfferApplied,
        handleOfferNameChange: handleOfferNameChange,
        handleOfferDescriptionChange: handleOfferDescriptionChange,
        handleOffer: handleOffer,
        resetOffer: resetOffer,
    };

    return <OfferContext.Provider value={contextValue}>{props.children}</OfferContext.Provider>;
};

export default OfferContext;
