import React, { useEffect, useState } from 'react';
import './ToggleSwitch.css';

function ToggleSwitch({ handleSwitch, isDeliveryFlag }) {
  const [isDelivery, setIsDelivery] = useState(isDeliveryFlag);

  useEffect(() => {
    setIsDelivery(isDeliveryFlag)
  }, [isDeliveryFlag])
  

  const handleToggle = (isDeliveryFlag) => {
    setIsDelivery(isDeliveryFlag);
    handleSwitch(isDeliveryFlag);
  }

  return (
    <div className="toggle-container">
      <div className="toggle-switch">
        <div
          className={`toggle-option ${isDelivery ? 'active' : ''}`}
          onClick={() => handleToggle(true)}
        >
          Delivery
        </div>
        <div
          className={`toggle-option ${!isDelivery ? 'active' : ''}`}
          onClick={() => handleToggle(false)}
        >
          Pick up
        </div>
        <div className={`toggle-slider ${isDelivery ? '' : 'right'}`}></div>
      </div>
    </div>
  );
}

export default ToggleSwitch;
