import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  storeData: [],
}

export const StoreSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    pushStoreData: (state, action) => {
      state.storeData = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {  pushStoreData } = StoreSlice.actions

export default StoreSlice.reducer