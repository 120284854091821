import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    TextField,
    Divider,
    IconButton,
    LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import NearMeIcon from '@mui/icons-material/NearMe';
import './StoreLocationPopup.css';
import axios from "axios";
import axiosApiFunction from "../../utils/axiosapifunc";

const StoreLocationPopup = ({ open, onClose, onSelectLocation, locations }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [geolocationMsg, setGeolocationMsg] = useState("");
    const [geolocationError, setGeolocationError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [mapRespData, setMapRespData] = useState([]);
    const [storeWithDistance, setStoreWithDistance] = useState([]);
    const [locationData, setLocationData] = useState({});
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (process.env.REACT_APP_IS_GOOGLEMAP_ENABLED == "true" && locations?.length > 0) {
            getNearestStoreLocation();
        }
    }, [locationData]);
    
    const getNearestStoreLocation = async () => {
        if(locations?.length == 0) return;
    
        setIsLoading(true);
    
        if (!locationData) return; // Guard clause to avoid calling the API without valid location data
        if(!locationData.latitude && !locationData.longitude){
            setGeolocationError('Unable to fetch your current location');
        }

        // locations[0].latitude = "23.204081522536686";
        // locations[0].longitude = "72.59576156856167";

        // Filter out destinations with 0.000 latitude or longitude
        const validLocations = locations.filter(coord => parseInt(coord.latitude) != 0 || parseInt(coord.longitude) != 0);

        if (validLocations.length === 0) {
            setGeolocationError('No valid destination coordinates found');
            setIsLoading(false);
            return;
        }

        const origins = `${locationData.latitude},${locationData.longitude}`;
        const destinations = locations.map(coord => `${coord.latitude},${coord.longitude}`).join('|');

        try {
            let resp = await axiosApiFunction(
                "get",
                "/api/v1/getneareststore",
                {
                    origins, destinations,
                },
                // userToken
            );

            if (resp.status === 200) {
                const updatedLocation = locations.map((location, index) => ({
                    ...location,
                    distance: resp.data.data[index]?.distance?.value <= 10000
                        ? resp.data.data[index]?.distance?.value
                        : -1,
                    km: resp.data.data[index]?.distance?.text || "",
                    duration: resp.data.data[index]?.duration?.text || ""
                }))
                    .filter(location => location.distance !== -1)
                    .sort((a, b) => a.distance - b.distance);

                setStoreWithDistance(updatedLocation);
            }
        } catch (error) {
            console.error("Error fetching nearby stores:", error);
            // Handle error as needed
        } finally {
            setIsLoading(false);
        }
    };

    const getNearbyLocation = async () => {
        setGeolocationMsg("");
        setGeolocationError("");

        const requestLocation = () => {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setGeolocationMsg('Location Fetched');
                    setLocationData(position.coords); // Set location data
                },
                error => {
                    setGeolocationError(error.message);
                }
            );
        };

        if (navigator.permissions) {
            try {
                const permission = await navigator.permissions.query({ name: "geolocation" });
                if (permission.state === "granted") {
                    requestLocation();
                } else if (permission.state === "prompt") {
                    requestLocation(); // Request location if permission is prompt
                } else {
                    setGeolocationError('Location permission denied');
                }
            } catch (error) {
                setGeolocationError('Error checking location permission: ' + error.message);
            }
        } else {
            requestLocation(); // Fallback for browsers that don't support permissions
        }
    };

    if (geolocationError || geolocationMsg) {
        setTimeout(() => {
            setGeolocationError("");
            setGeolocationMsg("");
        }, 2000);
    }

    const handleSelect = (location) => {
        // if (location.active == "true" || location.active == true) {
            onSelectLocation(location);
            onClose();
        // }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };


    const handleSetPreviousLocation = () => {
        setStoreWithDistance([]);
    }

    const filteredStores = (
        Array.isArray(storeWithDistance) && storeWithDistance.length > 0
            ? storeWithDistance
            : Array.isArray(locations) && locations.length > 0
            ? locations
            : []
    ).filter(
        (location) =>
            location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            location.address.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={open}
                onClose={onClose}
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                    borderRadius: "20px",
                }
                }}
                className="model-backdrop"
        >
            <DialogTitle sx={{ m: 1 }} id="customized-dialog-title" className="sm-location-title">
                Select Store
            </DialogTitle>
            <Divider />
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {geolocationError && <Typography className="geo-error">{geolocationError}</Typography>}
            {/* {geolocationMsg && <Typography className="geo-success">{geolocationMsg}</Typography>} */}
            <DialogContent>
                <div className="sp-search-nearby">
                    {storeWithDistance.length == 0 && <Button sx={{mt: 1}} className="nearby-button" variant="contained" color="success" onClick={getNearbyLocation}> <NearMeIcon />&nbsp; GET NEARBY STORES</Button>}
                    
                    <Box
                        
                    >
                        <TextField
                            fullWidth
                            placeholder="Search store locations here"
                            value={searchQuery}
                            onChange={handleSearch}
                            inputRef={searchInputRef}
                            inputProps={{
                                maxLength: 30,
                                autoComplete: "off",
                            }}
                            className="store-search-input"
                        />
                    </Box>
                </div>
                {isLoading && <LinearProgress color="success" />}
                <Box
                    sx={{
                        width: "100%",
                        height: 300,
                        overflowY: "auto",
                        overflowX: "hidden",
                        // padding: 2,
                    }}
                >
                    {filteredStores.length > 0 ? (
                        filteredStores.map((location, index) => (
                            <Box
                                key={index}
                                onClick={() => handleSelect(location)}
                                sx={{
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    cursor: "pointer",
                                    backgroundColor:
                                        location.active == "true" || location.active == true
                                            ? "transparent"
                                            : "#f8d7da",
                                    color:
                                        location.active == "true" || location.active == true
                                            ? "inherit"
                                            : "#721c24",
                                    "&:hover": (location.active == "true" ||
                                        location.active == true) && {
                                        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                                    },
                                }}
                            >
                                <div className="" style={{ display: "flex", alignItems: "flex-start", padding: "10px", border: "1px solid #ccc", borderRadius: "8px" }}>
                                    <div className="ssl-icon" style={{ marginRight: "10px" }}>
                                        <span style={{ color: "green" }}>
                                            <AddLocationIcon />
                                        </span>
                                    </div>
                                    <div className="ssl-content" style={{ flex: 1 }}>
                                        <Typography variant="h6" className="sm-location-name" style={{ marginBottom: "5px" }}>
                                            {location.name}
                                        </Typography>
                                        <Typography variant="body2" className="sm-location-address" color="textSecondary" style={{ marginBottom: "8px" }}>
                                            {location.address}
                                        </Typography>
                                        {!(location.active == "true" || location.active == true) && (
                                            <Typography variant="caption" color="error" style={{ marginTop: "10px" }}>
                                                Store Closed
                                            </Typography>
                                        )}
                                    </div>
                                    <div className="distance-view">
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                            <Typography className="sp-kms" style={{ fontSize: "16px", color: "green", marginBottom: "4px" }}>
                                                {location.distance > 0 ? location?.km : ""}
                                            </Typography>
                                            <Typography className="sp-destinationtime" style={{ fontSize: "14px", color: "#555" }}>
                                                {location.distance > 0 ? location?.duration : ""}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                            </Box>
                        ))
                    ) : (
                        <div className="no-search-found">
                            <Typography variant="body2">
                                {`No Result Found For `}
                                <span className="no-search-found-span">"{searchQuery}"</span>
                            </Typography>
                        </div>
                    )}
                </Box>

                {storeWithDistance.length > 0 && <div className="sp-back-button">
                    <Button variant="outlined" color="success" onClick={handleSetPreviousLocation} >Back</Button>
                </div>}
            </DialogContent>
        </Dialog>
    );
};

export default StoreLocationPopup;
